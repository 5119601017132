/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { computed, type MaybeRef } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'
import useEngagementNavigation from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'

export default function useBankNavigation(
  organisation: MaybeRef<Organisation>,
  engagement: MaybeRef<Engagement>,
  phase: MaybeRef<Phase>,
) {
  const { navigateTo } = useNavigation()
  const { getEngagementLocalePath } = useEngagementNavigation(organisation, engagement, phase)

  const bankIndexLocalePath = computed(() => getEngagementLocalePath('bank-Index'))
  const bankOverviewLocalePath = computed(() => getEngagementLocalePath('bank-Overview'))
  const bankStatementEntryValidationLocalePath = computed(() =>
    getEngagementLocalePath('bank-BankStatementEntryValidation'),
  )
  const bankStatementGeneralLedgerReconciliationLocalePath = computed(() =>
    getEngagementLocalePath('bank-BankStatementJournalEntryReconciliation'),
  )
  const bankStatementEntryDetailsLocalePath = computed(() =>
    getEngagementLocalePath('bank-BankStatementEntryDetails'),
  )
  const bankStatementEntryDetailCorruptionPerceptionIndexLocalePath = computed(() =>
    getEngagementLocalePath('bank-BankStatementEntryDetailCorruptionPerceptionIndices'),
  )
  const bankStatementEntryDetailMultipleBankAccountsLocalePath = computed(() =>
    getEngagementLocalePath('bank-BankStatementEntryDetailMultipleBankAccounts'),
  )
  const bankStatementEntryDetailMultipleCustomersLocalePath = computed(() =>
    getEngagementLocalePath('bank-BankStatementEntryDetailMultipleCustomers'),
  )
  const bankStatementEntryDetailsExplorerLocalePath = computed(() =>
    getEngagementLocalePath('bank-explorer-BankStatementEntryDetails'),
  )

  const navigateToBankIndexPage = async (): Promise<void> => {
    await navigateTo(bankIndexLocalePath.value)
  }

  const navigateToBankOverviewPage = async (): Promise<void> => {
    await navigateTo(bankOverviewLocalePath.value)
  }

  const navigateToBankStatementEntryDetailsPage = async (): Promise<void> => {
    await navigateTo(bankStatementEntryDetailsLocalePath.value)
  }

  const navigateToBankStatementEntryDetailsExplorerPage = async (): Promise<void> => {
    await navigateTo(bankStatementEntryDetailsExplorerLocalePath.value)
  }

  return {
    bankIndexLocalePath,
    bankOverviewLocalePath,
    bankStatementEntryValidationLocalePath,
    bankStatementGeneralLedgerReconciliationLocalePath,
    bankStatementEntryDetailsLocalePath,
    bankStatementEntryDetailCorruptionPerceptionIndexLocalePath,
    bankStatementEntryDetailMultipleBankAccountsLocalePath,
    bankStatementEntryDetailMultipleCustomersLocalePath,
    bankStatementEntryDetailsExplorerLocalePath,
    navigateToBankIndexPage,
    navigateToBankOverviewPage,
    navigateToBankStatementEntryDetailsPage,
    navigateToBankStatementEntryDetailsExplorerPage,
  }
}
